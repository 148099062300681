import React from "react";
import PointsSelector from "./PointsSelector";

export default () => {
  return (
    <>
      <div className="Term">
        <PointsSelector />
      </div>
    </>
  );
};
