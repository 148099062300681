import React, { useState } from "react";
import { FaChevronUp, FaChevronDown } from "react-icons/fa";
import "./Term.scss";

export default () => {
  const [points, setPoints] = useState(0);

  const removePoints = () => {
    if (points > 0) {
      setPoints(points - 1);
    }
  };

  return (
    <div className="PointsSelector">
      <FaChevronUp
        data-testid="add-points-btn"
        onClick={() => setPoints(points + 1)}
      />
      <span data-testid="assigned-points" className="PointsSelector-points">
        {points}
      </span>
      <FaChevronDown data-testid="remove-points-btn" onClick={removePoints} />
    </div>
  );
};
